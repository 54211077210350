/*** Links to AWS S3 media ***/
const assets_base_url = 'https://soundingclimate-media.s3.us-east-2.amazonaws.com';
//const assets_base_url = 'http://localhost:3000';

export const urlPre = assets_base_url + "/images";
export const precipKey = assets_base_url + "/images/interface/precipLegend1.svg";
export const tempKey = assets_base_url + "/images/interface/tempLegend2.svg";
export const iceKey = assets_base_url + "/images/interface/seaiceLegend.svg";
export const playUrl = assets_base_url + "/images/interface/playbutton.svg";
export const pauseUrl = assets_base_url + "/images/interface/stop.svg";
export const homeButton = assets_base_url + "/images/interface/UCAR_btn_home_active.svg";
export const graphKey = assets_base_url + "/images/interface/linegraphkey1.svg";
export const topSkinnyImg = assets_base_url + "/images/interface/sidelabeltopMixed.svg";
export const bottomSkinnyImg = assets_base_url + "/images/interface/sidelabelbottomMixed.svg";
export const timelineImg = assets_base_url + "/images/interface/timelinenumbersimage.svg";
export const loading = assets_base_url + "/images/interface/wait.svg";

/*** used to preload images in the AllTogether page ***/
export const togetherArtifactImgs = [
	precipKey,
	tempKey,
	iceKey,
	playUrl,
	pauseUrl,
	homeButton,
	graphKey,
	topSkinnyImg,
	bottomSkinnyImg,
	timelineImg,
	loading
];

export const precipActive = assets_base_url + "/images/interface/UCAR_btn_precipitation_active.svg";
export const precipInactive = assets_base_url + "/images/interface/UCAR_btn_precipitation_inactive.svg";
export const tempActive = assets_base_url + "/images/interface/UCAR_btn_temperature_active.svg";
export const tempInactive = assets_base_url + "/images/interface/UCAR_btn_temperature_inactive.svg";
export const iceActive = assets_base_url + "/images/interface/UCAR_btn_seaice_active.svg";
export const iceInactive = assets_base_url + "/images/interface/UCAR_btn_seaice_inactive.svg";
export const topSkinnyImgAlone = assets_base_url + "/images/interface/UCAR_sidebar_advanced_part2.svg";
export const bottomSkinnyImgAlone = assets_base_url + "/images/interface/UCAR_sidebar_advanced_part1.svg";

/*** used to preload images in the EachAlone page ***/
export const aloneArtifactImgs = [
	precipActive,
	precipInactive,
	tempActive,
	tempInactive,
	iceActive,
	iceInactive,
	precipKey,
	tempKey,
	iceKey,
	playUrl,
	pauseUrl,
	homeButton,
	graphKey,
	topSkinnyImgAlone,
	bottomSkinnyImgAlone,
	timelineImg,
	loading
];

export const eachAloneButton = assets_base_url + "/images/interface/btn_advBkg.png";
export const allTogetherButton = assets_base_url + "/images/interface/btn_basicBkg.png";
export const qrImg = assets_base_url + "/images/interface/articleqr.png";

export const precipImgs = [
	assets_base_url + "/images/precip/precip_ens0.jpg",
	assets_base_url + "/images/precip/precip_ens1.jpg",
	assets_base_url + "/images/precip/precip_ens2.jpg",
	assets_base_url + "/images/precip/precip_ens3.jpg",
	assets_base_url + "/images/precip/precip_ens4.jpg",
	assets_base_url + "/images/precip/precip_ens5.jpg",
	assets_base_url + "/images/precip/precip_ens6.jpg",
	assets_base_url + "/images/precip/precip_ens7.jpg",
	assets_base_url + "/images/precip/precip_ens8.jpg",
	assets_base_url + "/images/precip/precip_ens9.jpg",
	assets_base_url + "/images/precip/precip_ens10.jpg",
	assets_base_url + "/images/precip/precip_ens11.jpg",
	assets_base_url + "/images/precip/precip_ens12.jpg",
	assets_base_url + "/images/precip/precip_ens13.jpg",
	assets_base_url + "/images/precip/precip_ens14.jpg",
	assets_base_url + "/images/precip/precip_ens15.jpg",
	assets_base_url + "/images/precip/precip_ens16.jpg",
	assets_base_url + "/images/precip/precip_ens17.jpg",
	assets_base_url + "/images/precip/precip_ens18.jpg",
	assets_base_url + "/images/precip/precip_ens19.jpg",
	assets_base_url + "/images/precip/precip_ens20.jpg",
	assets_base_url + "/images/precip/precip_ens21.jpg",
	assets_base_url + "/images/precip/precip_ens22.jpg",
	assets_base_url + "/images/precip/precip_ens23.jpg",
	assets_base_url + "/images/precip/precip_ens24.jpg",
	assets_base_url + "/images/precip/precip_ens25.jpg",
	assets_base_url + "/images/precip/precip_ens26.jpg",
	assets_base_url + "/images/precip/precip_ens27.jpg",
	assets_base_url + "/images/precip/precip_ens28.jpg",
	assets_base_url + "/images/precip/precip_ens29.jpg",
	assets_base_url + "/images/precip/precip_ens30.jpg",
	assets_base_url + "/images/precip/precip_ens31.jpg",
	assets_base_url + "/images/precip/precip_ens32.jpg",
	assets_base_url + "/images/precip/precip_ens33.jpg",
	assets_base_url + "/images/precip/precip_ens34.jpg",
	assets_base_url + "/images/precip/precip_ens35.jpg",
	assets_base_url + "/images/precip/precip_ens36.jpg",
	assets_base_url + "/images/precip/precip_ens37.jpg",
	assets_base_url + "/images/precip/precip_ens38.jpg",
	assets_base_url + "/images/precip/precip_ens39.jpg",
	assets_base_url + "/images/precip/precip_ens40.jpg",
	assets_base_url + "/images/precip/precip_ens41.jpg",
	assets_base_url + "/images/precip/precip_ens42.jpg",
	assets_base_url + "/images/precip/precip_ens43.jpg",
	assets_base_url + "/images/precip/precip_ens44.jpg",
	assets_base_url + "/images/precip/precip_ens45.jpg",
	assets_base_url + "/images/precip/precip_ens46.jpg",
	assets_base_url + "/images/precip/precip_ens47.jpg",
	assets_base_url + "/images/precip/precip_ens48.jpg",
	assets_base_url + "/images/precip/precip_ens49.jpg",
	assets_base_url + "/images/precip/precip_ens50.jpg",
	assets_base_url + "/images/precip/precip_ens51.jpg",
	assets_base_url + "/images/precip/precip_ens52.jpg",
	assets_base_url + "/images/precip/precip_ens53.jpg",
	assets_base_url + "/images/precip/precip_ens54.jpg",
	assets_base_url + "/images/precip/precip_ens55.jpg",
	assets_base_url + "/images/precip/precip_ens56.jpg",
	assets_base_url + "/images/precip/precip_ens57.jpg",
	assets_base_url + "/images/precip/precip_ens58.jpg",
	assets_base_url + "/images/precip/precip_ens59.jpg",
	assets_base_url + "/images/precip/precip_ens60.jpg",
	assets_base_url + "/images/precip/precip_ens61.jpg",
	assets_base_url + "/images/precip/precip_ens62.jpg",
	assets_base_url + "/images/precip/precip_ens63.jpg",
	assets_base_url + "/images/precip/precip_ens64.jpg",
	assets_base_url + "/images/precip/precip_ens65.jpg",
	assets_base_url + "/images/precip/precip_ens66.jpg",
	assets_base_url + "/images/precip/precip_ens67.jpg",
	assets_base_url + "/images/precip/precip_ens68.jpg",
	assets_base_url + "/images/precip/precip_ens69.jpg",
	assets_base_url + "/images/precip/precip_ens70.jpg",
	assets_base_url + "/images/precip/precip_ens71.jpg",
	assets_base_url + "/images/precip/precip_ens72.jpg",
	assets_base_url + "/images/precip/precip_ens73.jpg",
	assets_base_url + "/images/precip/precip_ens74.jpg",
	assets_base_url + "/images/precip/precip_ens75.jpg",
	assets_base_url + "/images/precip/precip_ens76.jpg",
	assets_base_url + "/images/precip/precip_ens77.jpg",
	assets_base_url + "/images/precip/precip_ens78.jpg",
	assets_base_url + "/images/precip/precip_ens79.jpg",
	assets_base_url + "/images/precip/precip_ens80.jpg",
	assets_base_url + "/images/precip/precip_ens81.jpg",
	assets_base_url + "/images/precip/precip_ens82.jpg",
	assets_base_url + "/images/precip/precip_ens83.jpg",
	assets_base_url + "/images/precip/precip_ens84.jpg",
	assets_base_url + "/images/precip/precip_ens85.jpg",
	assets_base_url + "/images/precip/precip_ens86.jpg",
	assets_base_url + "/images/precip/precip_ens87.jpg",
	assets_base_url + "/images/precip/precip_ens88.jpg",
	assets_base_url + "/images/precip/precip_ens89.jpg",
	assets_base_url + "/images/precip/precip_ens90.jpg",
	assets_base_url + "/images/precip/precip_ens91.jpg",
	assets_base_url + "/images/precip/precip_ens92.jpg",
	assets_base_url + "/images/precip/precip_ens93.jpg",
	assets_base_url + "/images/precip/precip_ens94.jpg",
	assets_base_url + "/images/precip/precip_ens95.jpg",
	assets_base_url + "/images/precip/precip_ens96.jpg",
	assets_base_url + "/images/precip/precip_ens97.jpg",
	assets_base_url + "/images/precip/precip_ens98.jpg",
	assets_base_url + "/images/precip/precip_ens99.jpg",
	assets_base_url + "/images/precip/precip_ens100.jpg",
	assets_base_url + "/images/precip/precip_ens101.jpg",
	assets_base_url + "/images/precip/precip_ens102.jpg",
	assets_base_url + "/images/precip/precip_ens103.jpg",
	assets_base_url + "/images/precip/precip_ens104.jpg",
	assets_base_url + "/images/precip/precip_ens105.jpg",
	assets_base_url + "/images/precip/precip_ens106.jpg",
	assets_base_url + "/images/precip/precip_ens107.jpg",
	assets_base_url + "/images/precip/precip_ens108.jpg",
	assets_base_url + "/images/precip/precip_ens109.jpg",
	assets_base_url + "/images/precip/precip_ens110.jpg",
	assets_base_url + "/images/precip/precip_ens111.jpg",
	assets_base_url + "/images/precip/precip_ens112.jpg",
	assets_base_url + "/images/precip/precip_ens113.jpg",
	assets_base_url + "/images/precip/precip_ens114.jpg",
	assets_base_url + "/images/precip/precip_ens115.jpg",
	assets_base_url + "/images/precip/precip_ens116.jpg",
	assets_base_url + "/images/precip/precip_ens117.jpg",
	assets_base_url + "/images/precip/precip_ens118.jpg",
	assets_base_url + "/images/precip/precip_ens119.jpg",
	assets_base_url + "/images/precip/precip_ens120.jpg",
	assets_base_url + "/images/precip/precip_ens121.jpg",
	assets_base_url + "/images/precip/precip_ens122.jpg",
	assets_base_url + "/images/precip/precip_ens123.jpg",
	assets_base_url + "/images/precip/precip_ens124.jpg",
	assets_base_url + "/images/precip/precip_ens125.jpg",
	assets_base_url + "/images/precip/precip_ens126.jpg",
	assets_base_url + "/images/precip/precip_ens127.jpg",
	assets_base_url + "/images/precip/precip_ens128.jpg",
	assets_base_url + "/images/precip/precip_ens129.jpg",
	assets_base_url + "/images/precip/precip_ens130.jpg",
	assets_base_url + "/images/precip/precip_ens131.jpg",
	assets_base_url + "/images/precip/precip_ens132.jpg",
	assets_base_url + "/images/precip/precip_ens133.jpg",
	assets_base_url + "/images/precip/precip_ens134.jpg",
	assets_base_url + "/images/precip/precip_ens135.jpg",
	assets_base_url + "/images/precip/precip_ens136.jpg",
	assets_base_url + "/images/precip/precip_ens137.jpg",
	assets_base_url + "/images/precip/precip_ens138.jpg",
	assets_base_url + "/images/precip/precip_ens139.jpg",
	assets_base_url + "/images/precip/precip_ens140.jpg",
	assets_base_url + "/images/precip/precip_ens141.jpg",
	assets_base_url + "/images/precip/precip_ens142.jpg",
	assets_base_url + "/images/precip/precip_ens143.jpg",
	assets_base_url + "/images/precip/precip_ens144.jpg",
	assets_base_url + "/images/precip/precip_ens145.jpg",
	assets_base_url + "/images/precip/precip_ens146.jpg",
	assets_base_url + "/images/precip/precip_ens147.jpg",
	assets_base_url + "/images/precip/precip_ens148.jpg",
	assets_base_url + "/images/precip/precip_ens149.jpg",
	assets_base_url + "/images/precip/precip_ens150.jpg",
	assets_base_url + "/images/precip/precip_ens151.jpg",
	assets_base_url + "/images/precip/precip_ens152.jpg",
	assets_base_url + "/images/precip/precip_ens153.jpg",
	assets_base_url + "/images/precip/precip_ens154.jpg",
	assets_base_url + "/images/precip/precip_ens155.jpg",
	assets_base_url + "/images/precip/precip_ens156.jpg",
	assets_base_url + "/images/precip/precip_ens157.jpg",
	assets_base_url + "/images/precip/precip_ens158.jpg",
	assets_base_url + "/images/precip/precip_ens159.jpg",
	assets_base_url + "/images/precip/precip_ens160.jpg",
	assets_base_url + "/images/precip/precip_ens161.jpg",
	assets_base_url + "/images/precip/precip_ens162.jpg",
	assets_base_url + "/images/precip/precip_ens163.jpg",
	assets_base_url + "/images/precip/precip_ens164.jpg",
	assets_base_url + "/images/precip/precip_ens165.jpg",
	assets_base_url + "/images/precip/precip_ens166.jpg",
	assets_base_url + "/images/precip/precip_ens167.jpg",
	assets_base_url + "/images/precip/precip_ens168.jpg",
	assets_base_url + "/images/precip/precip_ens169.jpg",
	assets_base_url + "/images/precip/precip_ens170.jpg",
	assets_base_url + "/images/precip/precip_ens171.jpg",
	assets_base_url + "/images/precip/precip_ens172.jpg",
	assets_base_url + "/images/precip/precip_ens173.jpg",
	assets_base_url + "/images/precip/precip_ens174.jpg",
	assets_base_url + "/images/precip/precip_ens175.jpg",
	assets_base_url + "/images/precip/precip_ens176.jpg",
	assets_base_url + "/images/precip/precip_ens177.jpg",
	assets_base_url + "/images/precip/precip_ens178.jpg",
	assets_base_url + "/images/precip/precip_ens179.jpg",
	assets_base_url + "/images/precip/precip_ens180.jpg"
];
export const tempImgs = [
	assets_base_url + "/images/temp/temp_ens0.jpg",
	assets_base_url + "/images/temp/temp_ens1.jpg",
	assets_base_url + "/images/temp/temp_ens2.jpg",
	assets_base_url + "/images/temp/temp_ens3.jpg",
	assets_base_url + "/images/temp/temp_ens4.jpg",
	assets_base_url + "/images/temp/temp_ens5.jpg",
	assets_base_url + "/images/temp/temp_ens6.jpg",
	assets_base_url + "/images/temp/temp_ens7.jpg",
	assets_base_url + "/images/temp/temp_ens8.jpg",
	assets_base_url + "/images/temp/temp_ens9.jpg",
	assets_base_url + "/images/temp/temp_ens10.jpg",
	assets_base_url + "/images/temp/temp_ens11.jpg",
	assets_base_url + "/images/temp/temp_ens12.jpg",
	assets_base_url + "/images/temp/temp_ens13.jpg",
	assets_base_url + "/images/temp/temp_ens14.jpg",
	assets_base_url + "/images/temp/temp_ens15.jpg",
	assets_base_url + "/images/temp/temp_ens16.jpg",
	assets_base_url + "/images/temp/temp_ens17.jpg",
	assets_base_url + "/images/temp/temp_ens18.jpg",
	assets_base_url + "/images/temp/temp_ens19.jpg",
	assets_base_url + "/images/temp/temp_ens20.jpg",
	assets_base_url + "/images/temp/temp_ens21.jpg",
	assets_base_url + "/images/temp/temp_ens22.jpg",
	assets_base_url + "/images/temp/temp_ens23.jpg",
	assets_base_url + "/images/temp/temp_ens24.jpg",
	assets_base_url + "/images/temp/temp_ens25.jpg",
	assets_base_url + "/images/temp/temp_ens26.jpg",
	assets_base_url + "/images/temp/temp_ens27.jpg",
	assets_base_url + "/images/temp/temp_ens28.jpg",
	assets_base_url + "/images/temp/temp_ens29.jpg",
	assets_base_url + "/images/temp/temp_ens30.jpg",
	assets_base_url + "/images/temp/temp_ens31.jpg",
	assets_base_url + "/images/temp/temp_ens32.jpg",
	assets_base_url + "/images/temp/temp_ens33.jpg",
	assets_base_url + "/images/temp/temp_ens34.jpg",
	assets_base_url + "/images/temp/temp_ens35.jpg",
	assets_base_url + "/images/temp/temp_ens36.jpg",
	assets_base_url + "/images/temp/temp_ens37.jpg",
	assets_base_url + "/images/temp/temp_ens38.jpg",
	assets_base_url + "/images/temp/temp_ens39.jpg",
	assets_base_url + "/images/temp/temp_ens40.jpg",
	assets_base_url + "/images/temp/temp_ens41.jpg",
	assets_base_url + "/images/temp/temp_ens42.jpg",
	assets_base_url + "/images/temp/temp_ens43.jpg",
	assets_base_url + "/images/temp/temp_ens44.jpg",
	assets_base_url + "/images/temp/temp_ens45.jpg",
	assets_base_url + "/images/temp/temp_ens46.jpg",
	assets_base_url + "/images/temp/temp_ens47.jpg",
	assets_base_url + "/images/temp/temp_ens48.jpg",
	assets_base_url + "/images/temp/temp_ens49.jpg",
	assets_base_url + "/images/temp/temp_ens50.jpg",
	assets_base_url + "/images/temp/temp_ens51.jpg",
	assets_base_url + "/images/temp/temp_ens52.jpg",
	assets_base_url + "/images/temp/temp_ens53.jpg",
	assets_base_url + "/images/temp/temp_ens54.jpg",
	assets_base_url + "/images/temp/temp_ens55.jpg",
	assets_base_url + "/images/temp/temp_ens56.jpg",
	assets_base_url + "/images/temp/temp_ens57.jpg",
	assets_base_url + "/images/temp/temp_ens58.jpg",
	assets_base_url + "/images/temp/temp_ens59.jpg",
	assets_base_url + "/images/temp/temp_ens60.jpg",
	assets_base_url + "/images/temp/temp_ens61.jpg",
	assets_base_url + "/images/temp/temp_ens62.jpg",
	assets_base_url + "/images/temp/temp_ens63.jpg",
	assets_base_url + "/images/temp/temp_ens64.jpg",
	assets_base_url + "/images/temp/temp_ens65.jpg",
	assets_base_url + "/images/temp/temp_ens66.jpg",
	assets_base_url + "/images/temp/temp_ens67.jpg",
	assets_base_url + "/images/temp/temp_ens68.jpg",
	assets_base_url + "/images/temp/temp_ens69.jpg",
	assets_base_url + "/images/temp/temp_ens70.jpg",
	assets_base_url + "/images/temp/temp_ens71.jpg",
	assets_base_url + "/images/temp/temp_ens72.jpg",
	assets_base_url + "/images/temp/temp_ens73.jpg",
	assets_base_url + "/images/temp/temp_ens74.jpg",
	assets_base_url + "/images/temp/temp_ens75.jpg",
	assets_base_url + "/images/temp/temp_ens76.jpg",
	assets_base_url + "/images/temp/temp_ens77.jpg",
	assets_base_url + "/images/temp/temp_ens78.jpg",
	assets_base_url + "/images/temp/temp_ens79.jpg",
	assets_base_url + "/images/temp/temp_ens80.jpg",
	assets_base_url + "/images/temp/temp_ens81.jpg",
	assets_base_url + "/images/temp/temp_ens82.jpg",
	assets_base_url + "/images/temp/temp_ens83.jpg",
	assets_base_url + "/images/temp/temp_ens84.jpg",
	assets_base_url + "/images/temp/temp_ens85.jpg",
	assets_base_url + "/images/temp/temp_ens86.jpg",
	assets_base_url + "/images/temp/temp_ens87.jpg",
	assets_base_url + "/images/temp/temp_ens88.jpg",
	assets_base_url + "/images/temp/temp_ens89.jpg",
	assets_base_url + "/images/temp/temp_ens90.jpg",
	assets_base_url + "/images/temp/temp_ens91.jpg",
	assets_base_url + "/images/temp/temp_ens92.jpg",
	assets_base_url + "/images/temp/temp_ens93.jpg",
	assets_base_url + "/images/temp/temp_ens94.jpg",
	assets_base_url + "/images/temp/temp_ens95.jpg",
	assets_base_url + "/images/temp/temp_ens96.jpg",
	assets_base_url + "/images/temp/temp_ens97.jpg",
	assets_base_url + "/images/temp/temp_ens98.jpg",
	assets_base_url + "/images/temp/temp_ens99.jpg",
	assets_base_url + "/images/temp/temp_ens100.jpg",
	assets_base_url + "/images/temp/temp_ens101.jpg",
	assets_base_url + "/images/temp/temp_ens102.jpg",
	assets_base_url + "/images/temp/temp_ens103.jpg",
	assets_base_url + "/images/temp/temp_ens104.jpg",
	assets_base_url + "/images/temp/temp_ens105.jpg",
	assets_base_url + "/images/temp/temp_ens106.jpg",
	assets_base_url + "/images/temp/temp_ens107.jpg",
	assets_base_url + "/images/temp/temp_ens108.jpg",
	assets_base_url + "/images/temp/temp_ens109.jpg",
	assets_base_url + "/images/temp/temp_ens110.jpg",
	assets_base_url + "/images/temp/temp_ens111.jpg",
	assets_base_url + "/images/temp/temp_ens112.jpg",
	assets_base_url + "/images/temp/temp_ens113.jpg",
	assets_base_url + "/images/temp/temp_ens114.jpg",
	assets_base_url + "/images/temp/temp_ens115.jpg",
	assets_base_url + "/images/temp/temp_ens116.jpg",
	assets_base_url + "/images/temp/temp_ens117.jpg",
	assets_base_url + "/images/temp/temp_ens118.jpg",
	assets_base_url + "/images/temp/temp_ens119.jpg",
	assets_base_url + "/images/temp/temp_ens120.jpg",
	assets_base_url + "/images/temp/temp_ens121.jpg",
	assets_base_url + "/images/temp/temp_ens122.jpg",
	assets_base_url + "/images/temp/temp_ens123.jpg",
	assets_base_url + "/images/temp/temp_ens124.jpg",
	assets_base_url + "/images/temp/temp_ens125.jpg",
	assets_base_url + "/images/temp/temp_ens126.jpg",
	assets_base_url + "/images/temp/temp_ens127.jpg",
	assets_base_url + "/images/temp/temp_ens128.jpg",
	assets_base_url + "/images/temp/temp_ens129.jpg",
	assets_base_url + "/images/temp/temp_ens130.jpg",
	assets_base_url + "/images/temp/temp_ens131.jpg",
	assets_base_url + "/images/temp/temp_ens132.jpg",
	assets_base_url + "/images/temp/temp_ens133.jpg",
	assets_base_url + "/images/temp/temp_ens134.jpg",
	assets_base_url + "/images/temp/temp_ens135.jpg",
	assets_base_url + "/images/temp/temp_ens136.jpg",
	assets_base_url + "/images/temp/temp_ens137.jpg",
	assets_base_url + "/images/temp/temp_ens138.jpg",
	assets_base_url + "/images/temp/temp_ens139.jpg",
	assets_base_url + "/images/temp/temp_ens140.jpg",
	assets_base_url + "/images/temp/temp_ens141.jpg",
	assets_base_url + "/images/temp/temp_ens142.jpg",
	assets_base_url + "/images/temp/temp_ens143.jpg",
	assets_base_url + "/images/temp/temp_ens144.jpg",
	assets_base_url + "/images/temp/temp_ens145.jpg",
	assets_base_url + "/images/temp/temp_ens146.jpg",
	assets_base_url + "/images/temp/temp_ens147.jpg",
	assets_base_url + "/images/temp/temp_ens148.jpg",
	assets_base_url + "/images/temp/temp_ens149.jpg",
	assets_base_url + "/images/temp/temp_ens150.jpg",
	assets_base_url + "/images/temp/temp_ens151.jpg",
	assets_base_url + "/images/temp/temp_ens152.jpg",
	assets_base_url + "/images/temp/temp_ens153.jpg",
	assets_base_url + "/images/temp/temp_ens154.jpg",
	assets_base_url + "/images/temp/temp_ens155.jpg",
	assets_base_url + "/images/temp/temp_ens156.jpg",
	assets_base_url + "/images/temp/temp_ens157.jpg",
	assets_base_url + "/images/temp/temp_ens158.jpg",
	assets_base_url + "/images/temp/temp_ens159.jpg",
	assets_base_url + "/images/temp/temp_ens160.jpg",
	assets_base_url + "/images/temp/temp_ens161.jpg",
	assets_base_url + "/images/temp/temp_ens162.jpg",
	assets_base_url + "/images/temp/temp_ens163.jpg",
	assets_base_url + "/images/temp/temp_ens164.jpg",
	assets_base_url + "/images/temp/temp_ens165.jpg",
	assets_base_url + "/images/temp/temp_ens166.jpg",
	assets_base_url + "/images/temp/temp_ens167.jpg",
	assets_base_url + "/images/temp/temp_ens168.jpg",
	assets_base_url + "/images/temp/temp_ens169.jpg",
	assets_base_url + "/images/temp/temp_ens170.jpg",
	assets_base_url + "/images/temp/temp_ens171.jpg",
	assets_base_url + "/images/temp/temp_ens172.jpg",
	assets_base_url + "/images/temp/temp_ens173.jpg",
	assets_base_url + "/images/temp/temp_ens174.jpg",
	assets_base_url + "/images/temp/temp_ens175.jpg",
	assets_base_url + "/images/temp/temp_ens176.jpg",
	assets_base_url + "/images/temp/temp_ens177.jpg",
	assets_base_url + "/images/temp/temp_ens178.jpg",
	assets_base_url + "/images/temp/temp_ens179.jpg",
	assets_base_url + "/images/temp/temp_ens180.jpg"
];

export const iceImgs = [
	assets_base_url + "/images/seaIce/ice_ens0.jpg",
	assets_base_url + "/images/seaIce/ice_ens1.jpg",
	assets_base_url + "/images/seaIce/ice_ens2.jpg",
	assets_base_url + "/images/seaIce/ice_ens3.jpg",
	assets_base_url + "/images/seaIce/ice_ens4.jpg",
	assets_base_url + "/images/seaIce/ice_ens5.jpg",
	assets_base_url + "/images/seaIce/ice_ens6.jpg",
	assets_base_url + "/images/seaIce/ice_ens7.jpg",
	assets_base_url + "/images/seaIce/ice_ens8.jpg",
	assets_base_url + "/images/seaIce/ice_ens9.jpg",
	assets_base_url + "/images/seaIce/ice_ens10.jpg",
	assets_base_url + "/images/seaIce/ice_ens11.jpg",
	assets_base_url + "/images/seaIce/ice_ens12.jpg",
	assets_base_url + "/images/seaIce/ice_ens13.jpg",
	assets_base_url + "/images/seaIce/ice_ens14.jpg",
	assets_base_url + "/images/seaIce/ice_ens15.jpg",
	assets_base_url + "/images/seaIce/ice_ens16.jpg",
	assets_base_url + "/images/seaIce/ice_ens17.jpg",
	assets_base_url + "/images/seaIce/ice_ens18.jpg",
	assets_base_url + "/images/seaIce/ice_ens19.jpg",
	assets_base_url + "/images/seaIce/ice_ens20.jpg",
	assets_base_url + "/images/seaIce/ice_ens21.jpg",
	assets_base_url + "/images/seaIce/ice_ens22.jpg",
	assets_base_url + "/images/seaIce/ice_ens23.jpg",
	assets_base_url + "/images/seaIce/ice_ens24.jpg",
	assets_base_url + "/images/seaIce/ice_ens25.jpg",
	assets_base_url + "/images/seaIce/ice_ens26.jpg",
	assets_base_url + "/images/seaIce/ice_ens27.jpg",
	assets_base_url + "/images/seaIce/ice_ens28.jpg",
	assets_base_url + "/images/seaIce/ice_ens29.jpg",
	assets_base_url + "/images/seaIce/ice_ens30.jpg",
	assets_base_url + "/images/seaIce/ice_ens31.jpg",
	assets_base_url + "/images/seaIce/ice_ens32.jpg",
	assets_base_url + "/images/seaIce/ice_ens33.jpg",
	assets_base_url + "/images/seaIce/ice_ens34.jpg",
	assets_base_url + "/images/seaIce/ice_ens35.jpg",
	assets_base_url + "/images/seaIce/ice_ens36.jpg",
	assets_base_url + "/images/seaIce/ice_ens37.jpg",
	assets_base_url + "/images/seaIce/ice_ens38.jpg",
	assets_base_url + "/images/seaIce/ice_ens39.jpg",
	assets_base_url + "/images/seaIce/ice_ens40.jpg",
	assets_base_url + "/images/seaIce/ice_ens41.jpg",
	assets_base_url + "/images/seaIce/ice_ens42.jpg",
	assets_base_url + "/images/seaIce/ice_ens43.jpg",
	assets_base_url + "/images/seaIce/ice_ens44.jpg",
	assets_base_url + "/images/seaIce/ice_ens45.jpg",
	assets_base_url + "/images/seaIce/ice_ens46.jpg",
	assets_base_url + "/images/seaIce/ice_ens47.jpg",
	assets_base_url + "/images/seaIce/ice_ens48.jpg",
	assets_base_url + "/images/seaIce/ice_ens49.jpg",
	assets_base_url + "/images/seaIce/ice_ens50.jpg",
	assets_base_url + "/images/seaIce/ice_ens51.jpg",
	assets_base_url + "/images/seaIce/ice_ens52.jpg",
	assets_base_url + "/images/seaIce/ice_ens53.jpg",
	assets_base_url + "/images/seaIce/ice_ens54.jpg",
	assets_base_url + "/images/seaIce/ice_ens55.jpg",
	assets_base_url + "/images/seaIce/ice_ens56.jpg",
	assets_base_url + "/images/seaIce/ice_ens57.jpg",
	assets_base_url + "/images/seaIce/ice_ens58.jpg",
	assets_base_url + "/images/seaIce/ice_ens59.jpg",
	assets_base_url + "/images/seaIce/ice_ens60.jpg",
	assets_base_url + "/images/seaIce/ice_ens61.jpg",
	assets_base_url + "/images/seaIce/ice_ens62.jpg",
	assets_base_url + "/images/seaIce/ice_ens63.jpg",
	assets_base_url + "/images/seaIce/ice_ens64.jpg",
	assets_base_url + "/images/seaIce/ice_ens65.jpg",
	assets_base_url + "/images/seaIce/ice_ens66.jpg",
	assets_base_url + "/images/seaIce/ice_ens67.jpg",
	assets_base_url + "/images/seaIce/ice_ens68.jpg",
	assets_base_url + "/images/seaIce/ice_ens69.jpg",
	assets_base_url + "/images/seaIce/ice_ens70.jpg",
	assets_base_url + "/images/seaIce/ice_ens71.jpg",
	assets_base_url + "/images/seaIce/ice_ens72.jpg",
	assets_base_url + "/images/seaIce/ice_ens73.jpg",
	assets_base_url + "/images/seaIce/ice_ens74.jpg",
	assets_base_url + "/images/seaIce/ice_ens75.jpg",
	assets_base_url + "/images/seaIce/ice_ens76.jpg",
	assets_base_url + "/images/seaIce/ice_ens77.jpg",
	assets_base_url + "/images/seaIce/ice_ens78.jpg",
	assets_base_url + "/images/seaIce/ice_ens79.jpg",
	assets_base_url + "/images/seaIce/ice_ens80.jpg",
	assets_base_url + "/images/seaIce/ice_ens81.jpg",
	assets_base_url + "/images/seaIce/ice_ens82.jpg",
	assets_base_url + "/images/seaIce/ice_ens83.jpg",
	assets_base_url + "/images/seaIce/ice_ens84.jpg",
	assets_base_url + "/images/seaIce/ice_ens85.jpg",
	assets_base_url + "/images/seaIce/ice_ens86.jpg",
	assets_base_url + "/images/seaIce/ice_ens87.jpg",
	assets_base_url + "/images/seaIce/ice_ens88.jpg",
	assets_base_url + "/images/seaIce/ice_ens89.jpg",
	assets_base_url + "/images/seaIce/ice_ens90.jpg",
	assets_base_url + "/images/seaIce/ice_ens91.jpg",
	assets_base_url + "/images/seaIce/ice_ens92.jpg",
	assets_base_url + "/images/seaIce/ice_ens93.jpg",
	assets_base_url + "/images/seaIce/ice_ens94.jpg",
	assets_base_url + "/images/seaIce/ice_ens95.jpg",
	assets_base_url + "/images/seaIce/ice_ens96.jpg",
	assets_base_url + "/images/seaIce/ice_ens97.jpg",
	assets_base_url + "/images/seaIce/ice_ens98.jpg",
	assets_base_url + "/images/seaIce/ice_ens99.jpg",
	assets_base_url + "/images/seaIce/ice_ens100.jpg",
	assets_base_url + "/images/seaIce/ice_ens101.jpg",
	assets_base_url + "/images/seaIce/ice_ens102.jpg",
	assets_base_url + "/images/seaIce/ice_ens103.jpg",
	assets_base_url + "/images/seaIce/ice_ens104.jpg",
	assets_base_url + "/images/seaIce/ice_ens105.jpg",
	assets_base_url + "/images/seaIce/ice_ens106.jpg",
	assets_base_url + "/images/seaIce/ice_ens107.jpg",
	assets_base_url + "/images/seaIce/ice_ens108.jpg",
	assets_base_url + "/images/seaIce/ice_ens109.jpg",
	assets_base_url + "/images/seaIce/ice_ens110.jpg",
	assets_base_url + "/images/seaIce/ice_ens111.jpg",
	assets_base_url + "/images/seaIce/ice_ens112.jpg",
	assets_base_url + "/images/seaIce/ice_ens113.jpg",
	assets_base_url + "/images/seaIce/ice_ens114.jpg",
	assets_base_url + "/images/seaIce/ice_ens115.jpg",
	assets_base_url + "/images/seaIce/ice_ens116.jpg",
	assets_base_url + "/images/seaIce/ice_ens117.jpg",
	assets_base_url + "/images/seaIce/ice_ens118.jpg",
	assets_base_url + "/images/seaIce/ice_ens119.jpg",
	assets_base_url + "/images/seaIce/ice_ens120.jpg",
	assets_base_url + "/images/seaIce/ice_ens121.jpg",
	assets_base_url + "/images/seaIce/ice_ens122.jpg",
	assets_base_url + "/images/seaIce/ice_ens123.jpg",
	assets_base_url + "/images/seaIce/ice_ens124.jpg",
	assets_base_url + "/images/seaIce/ice_ens125.jpg",
	assets_base_url + "/images/seaIce/ice_ens126.jpg",
	assets_base_url + "/images/seaIce/ice_ens127.jpg",
	assets_base_url + "/images/seaIce/ice_ens128.jpg",
	assets_base_url + "/images/seaIce/ice_ens129.jpg",
	assets_base_url + "/images/seaIce/ice_ens130.jpg",
	assets_base_url + "/images/seaIce/ice_ens131.jpg",
	assets_base_url + "/images/seaIce/ice_ens132.jpg",
	assets_base_url + "/images/seaIce/ice_ens133.jpg",
	assets_base_url + "/images/seaIce/ice_ens134.jpg",
	assets_base_url + "/images/seaIce/ice_ens135.jpg",
	assets_base_url + "/images/seaIce/ice_ens136.jpg",
	assets_base_url + "/images/seaIce/ice_ens137.jpg",
	assets_base_url + "/images/seaIce/ice_ens138.jpg",
	assets_base_url + "/images/seaIce/ice_ens139.jpg",
	assets_base_url + "/images/seaIce/ice_ens140.jpg",
	assets_base_url + "/images/seaIce/ice_ens141.jpg",
	assets_base_url + "/images/seaIce/ice_ens142.jpg",
	assets_base_url + "/images/seaIce/ice_ens143.jpg",
	assets_base_url + "/images/seaIce/ice_ens144.jpg",
	assets_base_url + "/images/seaIce/ice_ens145.jpg",
	assets_base_url + "/images/seaIce/ice_ens146.jpg",
	assets_base_url + "/images/seaIce/ice_ens147.jpg",
	assets_base_url + "/images/seaIce/ice_ens148.jpg",
	assets_base_url + "/images/seaIce/ice_ens149.jpg",
	assets_base_url + "/images/seaIce/ice_ens150.jpg",
	assets_base_url + "/images/seaIce/ice_ens151.jpg",
	assets_base_url + "/images/seaIce/ice_ens152.jpg",
	assets_base_url + "/images/seaIce/ice_ens153.jpg",
	assets_base_url + "/images/seaIce/ice_ens154.jpg",
	assets_base_url + "/images/seaIce/ice_ens155.jpg",
	assets_base_url + "/images/seaIce/ice_ens156.jpg",
	assets_base_url + "/images/seaIce/ice_ens157.jpg",
	assets_base_url + "/images/seaIce/ice_ens158.jpg",
	assets_base_url + "/images/seaIce/ice_ens159.jpg",
	assets_base_url + "/images/seaIce/ice_ens160.jpg",
	assets_base_url + "/images/seaIce/ice_ens161.jpg",
	assets_base_url + "/images/seaIce/ice_ens162.jpg",
	assets_base_url + "/images/seaIce/ice_ens163.jpg",
	assets_base_url + "/images/seaIce/ice_ens164.jpg",
	assets_base_url + "/images/seaIce/ice_ens165.jpg",
	assets_base_url + "/images/seaIce/ice_ens166.jpg",
	assets_base_url + "/images/seaIce/ice_ens167.jpg",
	assets_base_url + "/images/seaIce/ice_ens168.jpg",
	assets_base_url + "/images/seaIce/ice_ens169.jpg",
	assets_base_url + "/images/seaIce/ice_ens170.jpg",
	assets_base_url + "/images/seaIce/ice_ens171.jpg",
	assets_base_url + "/images/seaIce/ice_ens172.jpg",
	assets_base_url + "/images/seaIce/ice_ens173.jpg",
	assets_base_url + "/images/seaIce/ice_ens174.jpg",
	assets_base_url + "/images/seaIce/ice_ens175.jpg",
	assets_base_url + "/images/seaIce/ice_ens176.jpg",
	assets_base_url + "/images/seaIce/ice_ens177.jpg",
	assets_base_url + "/images/seaIce/ice_ens178.jpg",
	assets_base_url + "/images/seaIce/ice_ens179.jpg",
	assets_base_url + "/images/seaIce/ice_ens180.jpg"
];


export const combinedImgs = [
	assets_base_url + "/images/combined/combined_ens0.jpg",
	assets_base_url + "/images/combined/combined_ens1.jpg",
	assets_base_url + "/images/combined/combined_ens2.jpg",
	assets_base_url + "/images/combined/combined_ens3.jpg",
	assets_base_url + "/images/combined/combined_ens4.jpg",
	assets_base_url + "/images/combined/combined_ens5.jpg",
	assets_base_url + "/images/combined/combined_ens6.jpg",
	assets_base_url + "/images/combined/combined_ens7.jpg",
	assets_base_url + "/images/combined/combined_ens8.jpg",
	assets_base_url + "/images/combined/combined_ens9.jpg",
	assets_base_url + "/images/combined/combined_ens10.jpg",
	assets_base_url + "/images/combined/combined_ens11.jpg",
	assets_base_url + "/images/combined/combined_ens12.jpg",
	assets_base_url + "/images/combined/combined_ens13.jpg",
	assets_base_url + "/images/combined/combined_ens14.jpg",
	assets_base_url + "/images/combined/combined_ens15.jpg",
	assets_base_url + "/images/combined/combined_ens16.jpg",
	assets_base_url + "/images/combined/combined_ens17.jpg",
	assets_base_url + "/images/combined/combined_ens18.jpg",
	assets_base_url + "/images/combined/combined_ens19.jpg",
	assets_base_url + "/images/combined/combined_ens20.jpg",
	assets_base_url + "/images/combined/combined_ens21.jpg",
	assets_base_url + "/images/combined/combined_ens22.jpg",
	assets_base_url + "/images/combined/combined_ens23.jpg",
	assets_base_url + "/images/combined/combined_ens24.jpg",
	assets_base_url + "/images/combined/combined_ens25.jpg",
	assets_base_url + "/images/combined/combined_ens26.jpg",
	assets_base_url + "/images/combined/combined_ens27.jpg",
	assets_base_url + "/images/combined/combined_ens28.jpg",
	assets_base_url + "/images/combined/combined_ens29.jpg",
	assets_base_url + "/images/combined/combined_ens30.jpg",
	assets_base_url + "/images/combined/combined_ens31.jpg",
	assets_base_url + "/images/combined/combined_ens32.jpg",
	assets_base_url + "/images/combined/combined_ens33.jpg",
	assets_base_url + "/images/combined/combined_ens34.jpg",
	assets_base_url + "/images/combined/combined_ens35.jpg",
	assets_base_url + "/images/combined/combined_ens36.jpg",
	assets_base_url + "/images/combined/combined_ens37.jpg",
	assets_base_url + "/images/combined/combined_ens38.jpg",
	assets_base_url + "/images/combined/combined_ens39.jpg",
	assets_base_url + "/images/combined/combined_ens40.jpg",
	assets_base_url + "/images/combined/combined_ens41.jpg",
	assets_base_url + "/images/combined/combined_ens42.jpg",
	assets_base_url + "/images/combined/combined_ens43.jpg",
	assets_base_url + "/images/combined/combined_ens44.jpg",
	assets_base_url + "/images/combined/combined_ens45.jpg",
	assets_base_url + "/images/combined/combined_ens46.jpg",
	assets_base_url + "/images/combined/combined_ens47.jpg",
	assets_base_url + "/images/combined/combined_ens48.jpg",
	assets_base_url + "/images/combined/combined_ens49.jpg",
	assets_base_url + "/images/combined/combined_ens50.jpg",
	assets_base_url + "/images/combined/combined_ens51.jpg",
	assets_base_url + "/images/combined/combined_ens52.jpg",
	assets_base_url + "/images/combined/combined_ens53.jpg",
	assets_base_url + "/images/combined/combined_ens54.jpg",
	assets_base_url + "/images/combined/combined_ens55.jpg",
	assets_base_url + "/images/combined/combined_ens56.jpg",
	assets_base_url + "/images/combined/combined_ens57.jpg",
	assets_base_url + "/images/combined/combined_ens58.jpg",
	assets_base_url + "/images/combined/combined_ens59.jpg",
	assets_base_url + "/images/combined/combined_ens60.jpg",
	assets_base_url + "/images/combined/combined_ens61.jpg",
	assets_base_url + "/images/combined/combined_ens62.jpg",
	assets_base_url + "/images/combined/combined_ens63.jpg",
	assets_base_url + "/images/combined/combined_ens64.jpg",
	assets_base_url + "/images/combined/combined_ens65.jpg",
	assets_base_url + "/images/combined/combined_ens66.jpg",
	assets_base_url + "/images/combined/combined_ens67.jpg",
	assets_base_url + "/images/combined/combined_ens68.jpg",
	assets_base_url + "/images/combined/combined_ens69.jpg",
	assets_base_url + "/images/combined/combined_ens70.jpg",
	assets_base_url + "/images/combined/combined_ens71.jpg",
	assets_base_url + "/images/combined/combined_ens72.jpg",
	assets_base_url + "/images/combined/combined_ens73.jpg",
	assets_base_url + "/images/combined/combined_ens74.jpg",
	assets_base_url + "/images/combined/combined_ens75.jpg",
	assets_base_url + "/images/combined/combined_ens76.jpg",
	assets_base_url + "/images/combined/combined_ens77.jpg",
	assets_base_url + "/images/combined/combined_ens78.jpg",
	assets_base_url + "/images/combined/combined_ens79.jpg",
	assets_base_url + "/images/combined/combined_ens80.jpg",
	assets_base_url + "/images/combined/combined_ens81.jpg",
	assets_base_url + "/images/combined/combined_ens82.jpg",
	assets_base_url + "/images/combined/combined_ens83.jpg",
	assets_base_url + "/images/combined/combined_ens84.jpg",
	assets_base_url + "/images/combined/combined_ens85.jpg",
	assets_base_url + "/images/combined/combined_ens86.jpg",
	assets_base_url + "/images/combined/combined_ens87.jpg",
	assets_base_url + "/images/combined/combined_ens88.jpg",
	assets_base_url + "/images/combined/combined_ens89.jpg",
	assets_base_url + "/images/combined/combined_ens90.jpg",
	assets_base_url + "/images/combined/combined_ens91.jpg",
	assets_base_url + "/images/combined/combined_ens92.jpg",
	assets_base_url + "/images/combined/combined_ens93.jpg",
	assets_base_url + "/images/combined/combined_ens94.jpg",
	assets_base_url + "/images/combined/combined_ens95.jpg",
	assets_base_url + "/images/combined/combined_ens96.jpg",
	assets_base_url + "/images/combined/combined_ens97.jpg",
	assets_base_url + "/images/combined/combined_ens98.jpg",
	assets_base_url + "/images/combined/combined_ens99.jpg",
	assets_base_url + "/images/combined/combined_ens100.jpg",
	assets_base_url + "/images/combined/combined_ens101.jpg",
	assets_base_url + "/images/combined/combined_ens102.jpg",
	assets_base_url + "/images/combined/combined_ens103.jpg",
	assets_base_url + "/images/combined/combined_ens104.jpg",
	assets_base_url + "/images/combined/combined_ens105.jpg",
	assets_base_url + "/images/combined/combined_ens106.jpg",
	assets_base_url + "/images/combined/combined_ens107.jpg",
	assets_base_url + "/images/combined/combined_ens108.jpg",
	assets_base_url + "/images/combined/combined_ens109.jpg",
	assets_base_url + "/images/combined/combined_ens110.jpg",
	assets_base_url + "/images/combined/combined_ens111.jpg",
	assets_base_url + "/images/combined/combined_ens112.jpg",
	assets_base_url + "/images/combined/combined_ens113.jpg",
	assets_base_url + "/images/combined/combined_ens114.jpg",
	assets_base_url + "/images/combined/combined_ens115.jpg",
	assets_base_url + "/images/combined/combined_ens116.jpg",
	assets_base_url + "/images/combined/combined_ens117.jpg",
	assets_base_url + "/images/combined/combined_ens118.jpg",
	assets_base_url + "/images/combined/combined_ens119.jpg",
	assets_base_url + "/images/combined/combined_ens120.jpg",
	assets_base_url + "/images/combined/combined_ens121.jpg",
	assets_base_url + "/images/combined/combined_ens122.jpg",
	assets_base_url + "/images/combined/combined_ens123.jpg",
	assets_base_url + "/images/combined/combined_ens124.jpg",
	assets_base_url + "/images/combined/combined_ens125.jpg",
	assets_base_url + "/images/combined/combined_ens126.jpg",
	assets_base_url + "/images/combined/combined_ens127.jpg",
	assets_base_url + "/images/combined/combined_ens128.jpg",
	assets_base_url + "/images/combined/combined_ens129.jpg",
	assets_base_url + "/images/combined/combined_ens130.jpg",
	assets_base_url + "/images/combined/combined_ens131.jpg",
	assets_base_url + "/images/combined/combined_ens132.jpg",
	assets_base_url + "/images/combined/combined_ens133.jpg",
	assets_base_url + "/images/combined/combined_ens134.jpg",
	assets_base_url + "/images/combined/combined_ens135.jpg",
	assets_base_url + "/images/combined/combined_ens136.jpg",
	assets_base_url + "/images/combined/combined_ens137.jpg",
	assets_base_url + "/images/combined/combined_ens138.jpg",
	assets_base_url + "/images/combined/combined_ens139.jpg",
	assets_base_url + "/images/combined/combined_ens140.jpg",
	assets_base_url + "/images/combined/combined_ens141.jpg",
	assets_base_url + "/images/combined/combined_ens142.jpg",
	assets_base_url + "/images/combined/combined_ens143.jpg",
	assets_base_url + "/images/combined/combined_ens144.jpg",
	assets_base_url + "/images/combined/combined_ens145.jpg",
	assets_base_url + "/images/combined/combined_ens146.jpg",
	assets_base_url + "/images/combined/combined_ens147.jpg",
	assets_base_url + "/images/combined/combined_ens148.jpg",
	assets_base_url + "/images/combined/combined_ens149.jpg",
	assets_base_url + "/images/combined/combined_ens150.jpg",
	assets_base_url + "/images/combined/combined_ens151.jpg",
	assets_base_url + "/images/combined/combined_ens152.jpg",
	assets_base_url + "/images/combined/combined_ens153.jpg",
	assets_base_url + "/images/combined/combined_ens154.jpg",
	assets_base_url + "/images/combined/combined_ens155.jpg",
	assets_base_url + "/images/combined/combined_ens156.jpg",
	assets_base_url + "/images/combined/combined_ens157.jpg",
	assets_base_url + "/images/combined/combined_ens158.jpg",
	assets_base_url + "/images/combined/combined_ens159.jpg",
	assets_base_url + "/images/combined/combined_ens160.jpg",
	assets_base_url + "/images/combined/combined_ens161.jpg",
	assets_base_url + "/images/combined/combined_ens162.jpg",
	assets_base_url + "/images/combined/combined_ens163.jpg",
	assets_base_url + "/images/combined/combined_ens164.jpg",
	assets_base_url + "/images/combined/combined_ens165.jpg",
	assets_base_url + "/images/combined/combined_ens166.jpg",
	assets_base_url + "/images/combined/combined_ens167.jpg",
	assets_base_url + "/images/combined/combined_ens168.jpg",
	assets_base_url + "/images/combined/combined_ens169.jpg",
	assets_base_url + "/images/combined/combined_ens170.jpg",
	assets_base_url + "/images/combined/combined_ens171.jpg",
	assets_base_url + "/images/combined/combined_ens172.jpg",
	assets_base_url + "/images/combined/combined_ens173.jpg",
	assets_base_url + "/images/combined/combined_ens174.jpg",
	assets_base_url + "/images/combined/combined_ens175.jpg",
	assets_base_url + "/images/combined/combined_ens176.jpg",
	assets_base_url + "/images/combined/combined_ens177.jpg",
	assets_base_url + "/images/combined/combined_ens178.jpg",
	assets_base_url + "/images/combined/combined_ens179.jpg",
	assets_base_url + "/images/combined/combined_ens180.jpg"
];

export const dbUrl = assets_base_url + "/data/";
